<template>
  <md-steppers
    :md-active-step.sync="stepper.active"
    md-linear
    md-vertical
    md-alternative
    @md-changed="fixDynamicTabContentHeight"
  >
    <tenant-step
      :stepper="stepper"
      :provisioning="provisioning"
      :tenant="tenant"
      :availableTenants="availableTenants"
      @select-tenant="setActiveTenant"
      @next-step="nextStep"
    />
    <region-step
      :stepper="stepper"
      :provisioning="provisioning"
      :tenant="tenant"
      :availableRegionsAndStages="availableRegionsAndStages"
      @next-step="nextStep"
    />
    <options-step
      :stepper="stepper"
      :provisioning="provisioning"
      :tenant="tenant"
      :productFlags="productFlags"
      :featureFlags="featureFlags"
      @next-step="nextStep"
    />
    <release-step
      ref="selectreleaseStep"
      :stepper="stepper"
      :provisioning="provisioning"
      :tenant="tenant"
      @next-step="nextStep"
    />
    <secrets-migration-step
      :stepper="stepper"
      :tenant="tenant"
      :provisioning="provisioning"
      @next-step="nextStep"
    />
    <review-step
      :stepper="stepper"
      :tenant="tenant"
      :provisioning="provisioning"
      :availableRegionsAndStages="availableRegionsAndStages"
      @provision-tenant="provisionTenant"
      @next-step="nextStep"
    />
    <provisioning-step :stepper="stepper" :provisioning="provisioning" />
    <report-step
      :stepper="stepper"
      :tenant="tenant"
      :provisioning="provisioning"
      :post-report-details="postReportDetails"
    />
  </md-steppers>
</template>

<script>
import "../../provision-hub";
import ReleaseStep from "@/components/ProvisionsPlugin/Wizard/ReleaseStep";
import TenantStep from "@/components/ProvisionsPlugin/Wizard/TenantStep";
import RegionStep from "@/components/ProvisionsPlugin/Wizard/RegionStep";
import OptionsStep from "@/components/ProvisionsPlugin/Wizard/OptionsStep";
import SecretsMigrationStep from "@/components/ProvisionsPlugin/Wizard/SecretsMigrationStep";
import ReviewStep from "@/components/ProvisionsPlugin/Wizard/ReviewStep";
import ProvisioningStep from "@/components/ProvisionsPlugin/Wizard/ProvisioningStep";
import ReportStep from "@/components/ProvisionsPlugin/Wizard/ReportStep";
import * as ProvisionConstants from "@/components/ProvisionsPlugin/ProvisionConstants";

const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;
export default {
  name: "provisions-wizard",
  components: {
    ReportStep,
    ProvisioningStep,
    ReviewStep,
    SecretsMigrationStep,
    OptionsStep,
    RegionStep,
    TenantStep,
    ReleaseStep
  },
  data() {
    return {
      reviewVerified: false,
      stepper: {
        active: "tenant",
        tenant: false,
        region: false,
        options: false,
        selectrelease: false,
        keepasstokeyvault: false,
        review: false,
        provisioning: false,
        postreport: false
      },
      productFlags: [],
      featureFlags: [],
      tenant: {
        tenantCode: "",
        cloudConsent: false,
        name: null,
        stages: [],
        featureFlags: [],
        productFlags: [],
        serviceUsername: "",
        writeToKeyVault: {
          write: false,
          stages: {}
        },
        octopusDeployments: {
          "CaseManagement - Configuration": {
            skip: false,
            selectedVersion: {}
          },
          "Friss Audit": { skip: false, selectedVersion: {} },
          "Tenant Web": { skip: true, selectedVersion: {} },
          "Side by Side": { skip: true, selectedVersion: {} }
        },
        ipAllowListing: {
          fcm: {},
          fi: {}
        }
      },
      postReportDetails: {
        instanceId: "",
        success: null,
        stages: []
      },
      provisioning: {
        started: false,
        ended: false,
        success: null,
        customStatus: "Please wait..."
      },
      availableTenants: [],
      availableRegionsAndStages: []
    };
  },
  watch: {
    "tenant.stages"(newVal) {
      var self = this;
      newVal.forEach(v => {
        self.tenant.writeToKeyVault.stages[v] = {};

        self.tenant.ipAllowListing.fcm[v] = { url: "", sftp: "" };
        self.tenant.ipAllowListing.fi[v] = { url: "", sftp: "" };

        self.tenant.octopusDeployments[
          this.OCTOPUS_PROJECTS.FCM
        ].selectedVersion[v] = "";
        self.tenant.octopusDeployments[
          this.OCTOPUS_PROJECTS.TENANT_WEB
        ].selectedVersion[v] = "";
        self.tenant.octopusDeployments[
          this.OCTOPUS_PROJECTS.SIDE_BY_SIDE
        ].selectedVersion[v] = "";
        self.tenant.octopusDeployments[
          this.OCTOPUS_PROJECTS.AUDIT
        ].selectedVersion[v] = "";

        self.tenant.octopusDeployments[
          this.OCTOPUS_PROJECTS.FCM
        ].selectedVersion[v] = "";
      });
    }
  },
  created() {
    this.$provisionHub.$on("receive-status-update", this.onReceiveStatusUpdate);
    this.$provisionHub.$on("provision-ended", this.onProvisionEnded);
  },
  async mounted() {
    try {
      const tenantsResult = await this.$featureFlagApi.get("/tenants");
      const regionsResult = await this.$featureFlagApi.get("/regions");

      this.availableTenants = tenantsResult.data;
      var regions = regionsResult.data;
      regions.map(c => {
        c.errors = {
          name: [],
          description: []
        };
      });
      this.availableRegionsAndStages = regions;
      this.featureFlags = ProvisionConstants.LEGACY_FEATURE_FLAGS;
      this.productFlags = ProvisionConstants.LEGACY_PRODUCT_FLAGS;
    } catch (error) {
      let message = "Failed to load data for provisioning";
      if (error.response) {
        message += `: ${error.response.data.errors.join(" ")}`;
      }

      this.$notify({
        message: message,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "danger"
      });
    }
  },
  computed: {
    OCTOPUS_PROJECTS() {
      return ProvisionConstants.OCTOPUS_PROJECTS;
    }
  },
  methods: {
    onReceiveStatusUpdate(status) {
      this.provisioning.customStatus = status.message ?? "Please wait...";
    },
    onProvisionEnded(payload) {
      this.nextStep("provisioning", "postreport");
      this.postReportDetails = payload.result;
      this.provisioning.ended = true;
      this.provisioning.success = payload.result.success;
      this.$provisionHub.provisionEnded(payload.result.instanceId);
    },
    setActiveTenant(index) {
      const tenant = this.availableTenants[index];
      const self = this;

      tenant.productFlags = this.productFlags.filter(
        p => self.isAllowedToUseFeature(p, tenant) && p.defaultValue === true
      );
      tenant.featureFlags = this.featureFlags.filter(
        f => self.isAllowedToUseFeature(f, tenant) && f.defaultValue === true
      );
      Object.assign(this.tenant, tenant);
    },
    isAllowedToUseFeature(item, tenant) {
      return item.requireCloudConsentFromTenant ? tenant.cloudConsent : true;
    },
    nextStep(id, index) {
      this.stepper[id] = true;
      if (index) {
        this.stepper.active = index;
      }
    },
    fixDynamicTabContentHeight(index) {
      // Hack that introduces coupling, but Vue Material is garbage with a dynamic content in tab.
      if (index == "allowlisting") {
        this.$refs.allowlistStep.fixDynamicTabContentHeight();
      } else if (index == "selectrelease") {
        this.$refs.selectreleaseStep.fixDynamicTabContentHeight();
      }
    },
    resetProvisioning() {
      this.provisioning.started = true;
      this.provisioning.ended = false;
      this.provisioning.success = null;
      this.provisioning.customStatus = "Please wait...";
    },
    async provisionTenant() {
      this.resetProvisioning();
      this.nextStep("review", "provisioning");
      var self = this;

      const header = {
        headers: { "requested-by": this.$adal.user.profile.email || this.$adal.user.userName }
      };
      await this.$provisionApi
        .post(
          `/provision/advance?code=${provisionApiCode}`,
          this.mapToRequest(this.tenant),
          header
        )
        .then(result => {
          self.$provisionHub.provisionStarted(result.data.id);
        })
        .catch(error => {
          let message = "Error contacting provisioning api...";
          this.nextStep("provisioning", "postreport");
          this.provisioning.ended = true;
          this.provisioning.success = false;
          this.provisioning.customStatus = message;
          this.$notify({
            message: message,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger"
          });
        });
    },
    mapToRequest(item) {
      var allFeatures = item.productFlags
        .map(p => p.code)
        .concat(item.featureFlags.map(f => f.code));

      var stages = item.stages.map(s => {
        var [region, stage, suffix] = s.split("-");

        // Do our shit we need to remap too
        if (region == "ca") region = "cn"; // China? Whyyyy.....
        // Ensure tenant has cloud tag if deploying to a cloud stage.
        if (suffix == "az") {
          allFeatures = allFeatures.concat("azure");
        }

        // Map to dictionary Octopus Project to Selected Version for a stage
        var octoReleaseVersions = {};
        for (const [key, value] of Object.entries(item.octopusDeployments)) {
          Object.assign(octoReleaseVersions, {
            [key]: value.selectedVersion[s]
          });
        }
        return {
          Features: allFeatures,
          Region: region,
          Stage: stage,
          OctopusReleaseVersion: octoReleaseVersions,
          ipAllowListing: item.ipAllowListing
        };
      });

      var tenant = {
        TenantCode: item.tenantCode,
        Name: item.name,
        Product: item.product,
        Country: item.country,
        Culture: item.language,
        OctopusDeployments: item.octopusDeployments,
        ServiceUsername: item.serviceUsername,
        TenantAdmins: item.tenantAdmins,
        Stages: stages
      };
      return tenant;
    }
  }
};
</script>
<style>
.md-steppers-navigation {
  box-shadow: none !important;
}
.md-button.md-stepper-header {
  background-color: white !important;
}
.md-menu-content-container .md-list-item {
  margin-left: 0px !important;
}
.md-checkbox.md-checked.md-disabled .md-checkbox-container {
  opacity: 1 !important;
}
</style>
<style scoped>
.md-field.md-disabled .md-input {
  cursor: default !important;
}
.subheader {
  padding-top: 15px;
}
.pad-bottom {
  padding-bottom: 15px;
}
</style>
