<template>
  <ValidationObserver ref="allowlistingForm" v-slot="{ invalid }">
    <md-dialog
      :md-active.sync="showDialog"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>
        IP Allowlisting for
        <strong>{{ tenant.name }}</strong>
      </md-dialog-title>
      <md-dialog-content class="md-scrollbar">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <div class="md-layout-item md-size-100">
              Before you begin, please read over the following points:
              <ul>
                <li>
                  Solvinity support ticket for manual load balancer
                  configuration must be completed. (To be automated soon!)
                </li>
                <li>
                  Select the stage and region that requires IP allowlisting.
                </li>
                <li>1 IP Address per line.</li>
                <li>
                  IP Addresses set below will be applied to Palo Alto and HA
                  Proxy.
                </li>
                <li>
                  Clicking Apply will execute an octopus runbook called
                  "Firewall Allowlisting"
                </li>
              </ul>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <md-field>
                      <label>Select a stage</label>
                      <md-select
                        name="stage"
                        id="stage"
                        v-model="tenant.ipAllowListing.region"
                      >
                        <md-optgroup
                          v-for="region in regionsAndStages"
                          :key="region.name"
                          :label="region.name"
                        >
                          <md-option
                            v-for="stage in region.stages"
                            :key="stage.name"
                            :value="getStageValue(region, stage)"
                          >
                            {{ stage.name }} ({{
                              region.regionCode.toUpperCase()
                            }})
                          </md-option>
                        </md-optgroup>
                      </md-select>
                      <span class="md-error">
                        {{ errors[0] }}
                      </span>
                    </md-field>
                  </ValidationProvider>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <md-field :class="{ 'md-invalid': errors.length > 0 }">
                      <label>Allowed IP Addresses</label>
                      <md-textarea
                        class="md-textarea"
                        v-model="tenant.ipAllowListing.ipaddresses"
                      />
                      <span class="md-error">
                        {{ errors[0] }}
                      </span>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="onClose">Close</md-button>
        <md-button class="md-primary" @click="onSave" :disabled="invalid">
          Save & Run
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required"
});

const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;

export default {
  name: "AllowlistingDialog",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      tenant: this.$tenantStore.state.currentTenant,
      regionsAndStages: []
    };
  },
  computed: {
    showDialog() {
      return this.$tenantStore.state.showAllowlistingDialog;
    }
  },
  async created() {
    const regionsResult = await this.$featureFlagApi.get(
      "/regions/ipallowlisting"
    );
    const regions = regionsResult.data;
    regions.map(c => {
      c.errors = { name: [], description: [] };
    });
    this.regionsAndStages = regions;
  },
  methods: {
    getStageValue(region, stage) {
      const suffix = stage.cloudBased ? "-az" : "-saas";
      return `${region.regionCode}-${stage.value}${suffix}`;
    },
    onClose() {
      this.$tenantStore.closeAllowlistingDialog();
    },
    async onSave() {
      let [region, stage, suffix] =
        this.tenant.ipAllowListing.region.split("-");
      if (region === "ca") region = "cn";

      const header = {
        headers: { "requested-by": this.$adal.user.profile.email || this.$adal.user.userName }
      };
      await this.$provisionApi
        .post(
          `/ip/allow?code=${provisionApiCode}`,
          {
            TenantCode: this.tenant.tenantCode,
            TenantName: this.tenant.name,
            Region: region,
            Stage: stage,
            IpAddresses: this.tenant.ipAllowListing.ipaddresses
          },
          header
        )
        .then(() => {
          this.$notify({
            message: "IP list were send to Octopus with success!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "success"
          });
          this.$tenantStore.closeAllowlistingDialog();
        })
        .catch(error => {
          let message = "Failed to allow list IPs...";
          if (error.response) {
            message += `: ${error.response.data.errors.join(" ")}`;
          }
          this.$notify({
            message: message,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger"
          });
        });
    }
  }
};
</script>

<style scoped>
.md-dialog-content {
  max-width: 1024px;
}
</style>
