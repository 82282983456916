<template>
  <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>
      Provision Tenant
      <strong>{{ currentTenant.name }}</strong>
    </md-dialog-title>
    <md-dialog-content class="md-scrollbar">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <md-tabs class="md-danger" md-alignment="centered" :md-active-tab="activeTab">
                <md-tab id="tab-provision" md-icon="cloud" md-label="Provision" :md-disabled="isProvisionInProgress">
                  <div class="md-layout">
                    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                          <div v-if="!hasDeploymentEnvironmentSet" class="md-">
                            <h4><b>No Deployment Environment has been set for the tenant.</b></h4>
                            <h6>Close this dialog and edit the tenant to select the environment before trying to
                              provision.</h6>
                          </div>
                          <h5>
                            <md-radio v-model="deployType" value="production" disabled>
                              Production Stack
                            </md-radio>
                          </h5>
                          <h5>
                            <md-radio v-model="deployType" value="internal" disabled>
                              Internal Environment
                            </md-radio>
                          </h5>
                          <hr />
                        </div>
                        <div class="md-layout-item md-size-50" v-if="deployType === 'production'">
                          <div class="md-layout-item md-size-100">
                            <md-field>
                              <label>Production Stack</label>
                              <md-select name="stage" id="stage" v-model="selectedRegion" disabled>
                                <md-optgroup label="Production">
                                  <md-option v-for="(item, key) in excludeInternalRegion(
                                    availableRegionsAndStages
                                  )" :key="key" :value="key">
                                    {{ getRegionHeader(key) }}
                                  </md-option>
                                </md-optgroup>
                              </md-select>
                            </md-field>
                          </div>
                          <div class="md-layout-item md-size-100">
                            When selecting a region (Canada, Europe, or United
                            States), the following stages will be deployed:
                            <ul>
                              <li>SPA</li>
                              <li>Acceptance (Azure)</li>
                              <li>Production (Azure)</li>
                            </ul>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-50" v-if="deployType === 'internal'">
                          <div class="md-layout-item md-size-100">
                            <md-field>
                              <label>Internal Environment</label>
                              <md-select name="stage" id="stage" v-model="selectedStage">
                                <md-optgroup label="Internal" v-if="availableRegionsAndStages.internal">
                                  <md-option v-for="item in availableRegionsAndStages.internal" :key="item.key"
                                    :value="item.key">
                                    {{ item.stageName }}
                                  </md-option>
                                </md-optgroup>
                              </md-select>
                            </md-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-50">
                          <div class="md-layout-item md-size-100">
                            This tenant will be deployed and integrated with the
                            following:
                            <ul>
                              <li>FRISS Screening Center <span
                                  v-if="deployCloudFsc && canShowDeployCloudFscToggle"><b>Cloud</b></span></li>
                              <li :style="deployFI && canShowDeployFIToggle
                                  ? ''
                                  : 'text-decoration: line-through'
                                ">
                                FRISS Investigations
                              </li>
                              <li>FRISS Cloud</li>
                              <li>FRISS Network Viewer</li>
                              <li>FRISS Audit (Legacy)</li>
                              <li>Side By Side (Legacy)</li>
                              <li>SSRS Reports (Legacy)</li>
                              <li>Data Lake Integration</li>
                              <li>Single Sign On</li>
                            </ul>
                          </div>
                          <div class="md-layout-item md-size-100" v-if="canShowDeployCloudFscToggle">
                            <md-checkbox v-model="deployCloudFsc">
                              Deploy FRISS Screening Center Cloud
                              <br />
                            </md-checkbox>
                          </div>
                          <div class="md-layout-item md-size-100" v-if="canShowDeployFIToggle">
                            <md-checkbox v-model="deployFI">
                              Deploy FRISS Investigations
                              <br />
                              <small>
                                (Only deployable for Spa, Acceptance and
                                Production)
                              </small>
                            </md-checkbox>
                          </div>
                          <div class="md-layout-item md-size-100">
                            <md-checkbox v-model="addSupportAsAdmin">
                              Add members of Support as Tenant Administrator
                              <br />
                            </md-checkbox>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </md-tab>

                <md-tab id="tab-status" md-icon="track_changes" md-label="Status" :md-disabled="!isProvisionInProgress">
                  <provision-status v-if="isProvisionInProgress"
                    :provision-response-metadata="provisionResponseMetadata" />
                </md-tab>
              </md-tabs>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="onClose">Close</md-button>
      <md-button class="md-primary" @click="save" :disabled="!isValid || isProvisionInProgress">
        Provision
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import ProvisionStatus from "@/components/ProvisionStatus/ProvisionStatus";
import { PRODUCT_FLAG_CODES } from "@/components/ProvisionsPlugin/ProvisionConstants";

const provisionApiCode = window.VUE_APP_PROVISION_API_CODE;

export default {
  name: "ProvisionDialog",
  components: { ProvisionStatus },
  data() {
    return {
      activeTab: "tab-provision",
      deployType: "production",
      availableRegionsAndStages: [],
      selectedRegion: "",
      selectedStage: "",
      provisionResponseMetadata: null,
      provisionStatus: null,
      pollStatusTimerId: null,
      deployFI: false,
      deployCloudFsc: false,
      addSupportAsAdmin: true
    };
  },
  async created() {
    try {
      const regionsResponse = await this.$featureFlagApi.get(
        "/regions/express"
      );
      this.availableRegionsAndStages = regionsResponse.data;

      let deployEnv = this.$tenantStore.state.currentTenant.deploymentEnvironment;
      if (deployEnv === "Development") {
        this.deployType = "internal";
      } else {
        this.selectedRegion = this.camelize(deployEnv); // need to camelCase because the key can be something like unitedStates
      }
    } catch (error) {
      this.$notify({
        message: "Failed to read data necessary to Deploy",
        icon: "running_with_errors",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "danger"
      });
    }
  },
  computed: {
    showDialog() {
      return this.$tenantStore.state.showProvisionDialog;
    },
    currentTenant() {
      return this.$tenantStore.state.currentTenant;
    },
    isValid() {
      return this.selectedRegion.length > 0 || this.selectedStage.length > 0;
    },
    hasDeploymentEnvironmentSet() {
      return this.$tenantStore.state.currentTenant.deploymentEnvironment;
    },
    isProvisionInProgress() {
      return this.activeTab === "tab-status" || !!this.provisionStatus;
    },
    canShowDeployFIToggle() {
      const stageSplit = this.selectedStage.split("-");
      return (
        this.deployType === "production" ||
        (this.deployType === "internal" && stageSplit[1] === "spa")
      );
    },
    canShowDeployCloudFscToggle() {
      return this.$tenantStore.state.currentTenant.cloudConsent;
    }
  },
  methods: {
    onClose() {
      this.$tenantStore.closeProvisionDialog();
      this.provisionStatus = null;
    },
    getRegionHeader(key) {
      return key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    excludeInternalRegion(regions) {
      const filtered = Object.fromEntries(
        Object.entries(regions).filter(([k, v]) => k !== "internal")
      );
      return filtered;
    },
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return "";
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
    async save() {
      let stages = [];

      if (this.deployType === "production") {
        for (let index in this.availableRegionsAndStages[this.selectedRegion]) {
          const value =
            this.availableRegionsAndStages[this.selectedRegion][index];
          stages = [...stages, { region: value.region, stage: value.stage }];
        }
      } else {
        const stageSplit = this.selectedStage.split("-");
        // Disable FRISS Investigate deployment for internal stages, except SPA.
        if (stageSplit[1] !== "spa") {
          this.deployFI = false;
        }
        stages = [{ region: stageSplit[0], stage: stageSplit[1] }];
      }

      let additionalProducts = [
        ...(this.deployFI ? [PRODUCT_FLAG_CODES.FI] : []),
        ...(this.deployCloudFsc ? [PRODUCT_FLAG_CODES.CLOUD_FSC] : [])
      ];

      const expressRequest = {
        TenantCode: this.currentTenant.tenantCode,
        Name: this.currentTenant.name,
        Product: this.currentTenant.product,
        Country: this.currentTenant.country,
        Culture: this.currentTenant.language,
        Domain: this.currentTenant.domain,
        TenantGroup: this.currentTenant.tenantGroup,
        TenantAdmins: this.currentTenant.tenantAdmins,
        AddSupportMembersAsTenantAdmin: this.addSupportAsAdmin,
        Stages: stages,
        AdditionalProducts: additionalProducts
      };

      this.activeTab = "tab-status";
      const header = {
        headers: { "requested-by": this.$adal.user.profile.email || this.$adal.user.userName }
      };
      await this.$provisionApi
        .post(
          `/provision/express?code=${provisionApiCode}`,
          expressRequest,
          header
        )
        .then(async result => {
          this.provisionResponseMetadata = result.data;
        })
        .catch(error => {
          let message = "Error contacting provisioning api...";
          this.provisionStatus.customStatus = message;
          this.$notify({
            message: message,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "danger"
          });
        });
    }
  }
};
</script>

<style scoped>
.md-tabs {
  margin-top: 24px !important;
  margin-left: -20px;
  margin-right: -20px;
}

.md-tab-nav-button {
  height: 32px !important;
}

.md-dialog-content {
  max-width: 1024px;
}
</style>
